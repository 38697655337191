import React, { Children, useState } from "react"
import { useForm, FormProvider, appendErrors } from "react-hook-form"
import { ContactGoogleForm } from "./ContactPause"
import { TextInput, Button, DatePicker, Select, Address } from "./ui"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import axios from "axios"
import dateFormat from "dateformat"
import { navigate } from "gatsby"
import { usePromiseTracker, trackPromise } from "react-promise-tracker"
import { Send, Mail, Phone, MapPin, Loader } from "react-feather"
import { motion } from "framer-motion"

const schema = yup.object().shape({
    name1: yup.string().required(`お客様のお名前を入力してください`),
    address1: yup.string().required(`お客様のご住所を選択してください`),
    address2: yup.string().required(`お客様のご番地を入力してください`),
    telephone: yup
        .string()
        .matches(
            /^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/,
            "有効な電話番号を入力してください"
        )
        .required(`連絡先のお電話番号を入力してください`),
    email: yup
        .string()
        .email(`正しいメールアドレスを入力してください`)
        .required(`お客様のメールアドレスを入力してください`),
    Sdate: yup.string().required(`お休み開始日を指定してください`),
    start: yup.string().required(`朝刊か夕刊どちらかを選択してください`),
    Edate: yup
        .date()
        .when(
            "Sdate",
            (Sdate, schema) =>
                Sdate &&
                schema.min(Sdate, "開始日よりも後の日付を選択してください")
        )
        .required(`お休み終了日を指定してください`),
    end: yup.string().required(`朝刊か夕刊どちらかを選択してください`),
    keep: yup.string().required(`どちらかを選択してください`),
})

const IconRight = ({ spin = false }) => {
    if (spin) {
        return (
            <span
                className="spin"
                style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    animationDuration: "5s",
                }}
            >
                <Loader />
            </span>
        )
    }
    return <Send />
}

type FormData = {
    name1: string
    name2: string
    address1: string
    address2: string
    address3: string
    telephone: string
    email: string
    Sdate: string
    start: string
    Edate: string
    end: string
    keep: string
    message: string
}

const Pause = () => {
    const { errors, ...methods } = useForm<FormData>({
        resolver: yupResolver(schema),
    })
    const [transactionState, setTransactionState] = useState(false)
    const { promiseInProgress } = usePromiseTracker()
    const submit = values => {
        // console.log(values)
        const Sdate = dateFormat(values.Sdate, "isoDate")
        const Edate = dateFormat(values.Edate, "isoDate")
        const GOOGLE_FORM_ACTION = ContactGoogleForm.action
        // CORS対策
        const CORS_PROXY = "https://cros-nikkei.herokuapp.com/"

        const submitParams = new FormData()
        submitParams.append(
            ContactGoogleForm.name,
            values.name1 + " " + values.name2
        )
        submitParams.append(
            ContactGoogleForm.address,
            values.address1 + values.address2 + " " + values.address3
        )
        submitParams.append(ContactGoogleForm.telephone, values.telephone)
        submitParams.append(ContactGoogleForm.email, values.email)
        submitParams.append(ContactGoogleForm.Sdate, Sdate)
        submitParams.append(ContactGoogleForm.start, values.start)
        submitParams.append(ContactGoogleForm.Edate, Edate)
        submitParams.append(ContactGoogleForm.end, values.end)
        submitParams.append(ContactGoogleForm.keep, values.keep)
        submitParams.append(ContactGoogleForm.message, values.message)

        trackPromise(
            axios
                .post(CORS_PROXY + GOOGLE_FORM_ACTION, submitParams)
                .then(() => {
                    navigate(`/thanks`)
                })
                .catch(error => {
                    navigate(`/error`)
                })
        )
    }
    return (
        <FormProvider errors={errors} {...methods}>
            <h4 className="text-center text-2xl text-color-4">
                一時配達休止のご連絡
            </h4>
            <div>
                ご旅行やご出張、年末年始などご不在されるときに
                <br className="hidden sm:visible" />
                新聞の配達を一時休止するサービスです。
                防犯のためにもぜひご利用下さい。
                <br />
                <br />
                休止中の新聞を配達再開時にまとめてお届けするサービスも承ります。
                <br />
                <span className="text-sm">
                    ※新聞休刊日は朝刊配達をお休みさせていただきますので
                    <br />
                    休刊日に再開をご希望の場合、おまとめ分は夕刊時の配達になります。
                    <br />
                    休止開始日が翌々日以降の場合のみご使用下さい。
                </span>
                <br />
                <br />
                休止開始日の3営業日前までにお申し込みください。
                <br />
                <br />
                再入開始日が未定の場合、お急ぎの場合はお電話・FAXでご連絡ください。　　
                <br />
                Tel：0120-21-1432　
                <br className="visible sm:hidden" />
                Fax：03-3552-8215
            </div>
            <form
                method="POST"
                onSubmit={methods.handleSubmit(submit)}
                noValidate
            >
                <TextInput
                    label="お名前・会社名(部署名)"
                    name="name1"
                    footer={errors.name1?.message}
                />
                <TextInput
                    label="担当者様のお名前"
                    name="name2"
                    footer={errors.name2?.message}
                />
                <Address
                    label="ご住所"
                    name="address1"
                    footer={errors.address1?.message}
                    value={[
                        { id: 1, value: "中央区 八重洲" },
                        { id: 2, value: "中央区 新富" },
                        { id: 3, value: "中央区 湊" },
                        { id: 4, value: "中央区 入船" },
                        { id: 5, value: "中央区 八丁堀" },
                        { id: 6, value: "中央区 新川" },
                        { id: 7, value: "中央区 銀座" },
                        { id: 8, value: "中央区 日本橋茅場町" },
                        { id: 9, value: "中央区 京橋" },
                        { id: 10, value: "千代田区 丸の内" },
                        { id: 11, value: "千代田区 有楽町" },
                        { id: 12, value: "港区 新橋" },
                        { id: 13, value: "港区 東新橋" },
                    ]}
                />
                <TextInput
                    label="番地"
                    name="address2"
                    footer={errors.address2?.message}
                />
                <TextInput
                    label="建物名・部屋番号"
                    name="address3"
                    footer={errors.address3?.message}
                />
                <TextInput
                    label="お電話番号"
                    name="telephone"
                    footer={errors.telephone?.message}
                />
                <TextInput
                    label="メールアドレス"
                    name="email"
                    type="email"
                    footer={errors.email?.message}
                />
                <DatePicker
                    label="いつから休止しますか？"
                    name="Sdate"
                    type="date"
                    min={new Date()}
                    footer={errors.Sdate?.message}
                />
                <Select
                    label="朝刊と夕刊どちらからですか？"
                    name="start"
                    footer={errors.start?.message}
                    value={[
                        { id: 1, value: "朝刊から" },
                        { id: 2, value: "夕刊から" },
                    ]}
                />
                <DatePicker
                    label="いつまで休止しますか？"
                    name="Edate"
                    type="date"
                    min={
                        methods.getValues("Sdate")
                            ? methods.getValues("Sdate")
                            : new Date()
                    }
                    footer={errors.Edate?.message}
                />
                <Select
                    label="朝刊と夕刊どちらまでですか？"
                    name="end"
                    footer={errors.end?.message}
                    value={[
                        { id: 1, value: "朝刊まで" },
                        { id: 2, value: "夕刊まで" },
                    ]}
                />
                <Select
                    label="休止中の新聞の取り置きについて"
                    name="keep"
                    footer={errors.keep?.message}
                    value={[
                        {
                            id: 1,
                            value: "休み中の新聞は不要",
                        },
                        {
                            id: 2,
                            value: "休み明けにまとめて届ける",
                        },
                    ]}
                />
                <TextInput label="通信欄" name="message" footer={""} />
                <div className="py-3 lg:p-4">
                    <Button
                        type="button,submit"
                        title="送信"
                        disabled={promiseInProgress}
                        iconRight={<IconRight spin={promiseInProgress} />}
                        aria-label="送信"
                    />
                </div>
            </form>
        </FormProvider>
    )
}

export default Pause
