import React, { Children, useState } from "react"
import { useForm, FormProvider, appendErrors } from "react-hook-form"
import { ContactGoogleForm } from "./ContactSub"
import { TextInput, Button, DatePicker, Select, Address } from "./ui"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import axios from "axios"
import dateFormat from "dateformat"
import { navigate } from "gatsby"
import { usePromiseTracker, trackPromise } from "react-promise-tracker"
import { Send, Mail, Phone, MapPin, Loader } from "react-feather"
import { motion } from "framer-motion"

const schema = yup.object().shape({
    name1: yup.string().required(`お客様のお名前を入力してください`),
    address1: yup.string().required(`お客様のご住所を選択してください`),
    address2: yup.string().required(`お客様の番地を入力してください`),
    telephone: yup
        .string()
        .matches(
            /^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/,
            "有効な電話番号を入力してください"
        )
        .required(`連絡先のお電話番号を入力してください`),
    email: yup
        .string()
        .email(`正しいメールアドレスを入力してください`)
        .required(`お客様のメールアドレスを入力してください`),
    Sdate: yup.string().required(`配達開始日を指定してください`),
    sub: yup.string().required(`どちらかを選択してください`),
    // paper: yup.string().required(`どれかを選択してください`),
    other: yup.string().when("paper", {
        is: "その他",
        then: yup.string().required(`新聞の銘柄を入力してください`),
    }),
    plan: yup.string().when("sub", {
        is: "ご購読",
        then: yup.string().required(`どれかを選択してください`),
    }),
})

const IconRight = ({ spin = false }) => {
    if (spin) {
        return (
            <span
                className="spin"
                style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    animationDuration: "5s",
                }}
            >
                <Loader />
            </span>
        )
    }
    return <Send />
}

type FormData = {
    name1: string
    name2: string
    address1: string
    address2: string
    address3: string
    telephone: string
    email: string
    Sdate: string
    sub: string
    paper: string
    other: string
    plan: string
    message: string
}

const Subscribe = () => {
    const { errors, watch, ...methods } = useForm<FormData>({
        resolver: yupResolver(schema),
    })
    const [transactionState, setTransactionState] = useState(false)
    const { promiseInProgress } = usePromiseTracker()
    const sub = watch("sub")
    const submit = values => {
        const Sdate = dateFormat(values.Sdate, "isoDate")
        const GOOGLE_FORM_ACTION = ContactGoogleForm.action
        // CORS対策
        const CORS_PROXY = "https://cros-nikkei.herokuapp.com/"

        const submitParams = new FormData()
        submitParams.append(
            ContactGoogleForm.name,
            values.name1 + " " + values.name2
        )
        submitParams.append(
            ContactGoogleForm.address,
            values.address1 + values.address2 + " " + values.address3
        )
        submitParams.append(ContactGoogleForm.telephone, values.telephone)
        submitParams.append(ContactGoogleForm.email, values.email)
        submitParams.append(ContactGoogleForm.sub, values.sub)
        submitParams.append(ContactGoogleForm.plan, values.plan)
        submitParams.append(ContactGoogleForm.Sdate, Sdate)
        submitParams.append(ContactGoogleForm.message, values.message)

        trackPromise(
            axios
                .post(CORS_PROXY + GOOGLE_FORM_ACTION, submitParams)
                .then(() => {
                    navigate(`/thanks`)
                })
                .catch(error => {
                    navigate(`/error`)
                })
        )
    }
    return (
        <FormProvider errors={errors} {...methods} watch={watch}>
            <h4 className="text-center text-2xl text-color-4">
                お試し・ご購読のお申込み
            </h4>
            <div>
                15時以降の急なご依頼は対応が遅れる場合があります。
                <br />
                お急ぎの場合はお電話・FAXでご連絡ください。　　
                <br />
                Tel：0120-21-1432　
                <br className="visible sm:hidden" />
                Fax：03-3552-8215
            </div>
            <form
                method="POST"
                onSubmit={methods.handleSubmit(submit)}
                noValidate
            >
                <TextInput
                    label="お名前・会社名(部署名)"
                    name="name1"
                    footer={errors.name1?.message}
                />
                <TextInput
                    label="担当者様のお名前"
                    name="name2"
                    footer={errors.name2?.message}
                />
                <Address
                    label="ご住所"
                    name="address1"
                    footer={errors.address1?.message}
                    value={[
                        { id: 1, value: "中央区 湊" },
                        { id: 2, value: "中央区 入船" },
                        { id: 3, value: "中央区 新富" },
                        { id: 4, value: "中央区 銀座" },
                        { id: 5, value: "中央区 八重洲" },
                        { id: 6, value: "中央区 京橋" },
                        { id: 7, value: "中央区 八丁堀" },
                        { id: 8, value: "中央区 日本橋茅場町" },
                        { id: 9, value: "中央区 新川" },
                        { id: 10, value: "千代田区 丸の内" },
                        { id: 11, value: "千代田区 有楽町" },
                    ]}
                />
                <TextInput
                    label="番地"
                    name="address2"
                    footer={errors.address2?.message}
                />
                <TextInput
                    label="建物名・部屋番号"
                    name="address3"
                    footer={errors.address3?.message}
                />
                <TextInput
                    label="お電話番号"
                    name="telephone"
                    footer={errors.telephone?.message}
                />
                <TextInput
                    label="メールアドレス"
                    name="email"
                    type="email"
                    footer={errors.email?.message}
                />
                <Select
                    label="「お試し」と「ご購読」どちらのお申し込みですか？"
                    name="sub"
                    footer={errors.sub?.message}
                    value={[
                        { id: 1, value: "お試し" },
                        { id: 2, value: "ご購読" },
                    ]}
                />
                <p className="text-xs px-10" style={{ color: "#347deb" }}>
                    商品の銘柄は通信欄にご入力下さい。
                </p>
                {sub === "ご購読" && (
                    <Select
                        label="「日本経済新聞（宅配）＋電子版セット」「日本経済新聞（宅配）」「電子版のみ」のどれかをお選びください"
                        name="plan"
                        footer={errors.plan?.message}
                        value={[
                            {
                                id: 1,
                                value: "日本経済新聞（宅配）＋電子版セット",
                            },
                            { id: 2, value: "日本経済新聞（宅配）" },
                            { id: 3, value: "電子版のみ" },
                        ]}
                    />
                    // <Check
                    //     label="新聞の種類を選択して下さい"
                    //     name="paper"
                    //     footer={errors.paper?.message}
                    //     value={[
                    //         { id: 1, value: "日経新聞" },
                    //         { id: 2, value: "日経産業新聞" },
                    //         { id: 3, value: "日経MJ" },
                    //         { id: 4, value: "毎日新聞" },
                    //     ]}
                    // />
                )}

                {/* {papercheck && (
                    <TextInput
                        label="その他の新聞の銘柄を入力して下さい"
                        name="other"
                        footer={errors.other?.message}
                    />
                )} */}
                <DatePicker
                    label="いつから配達開始しますか？"
                    name="Sdate"
                    type="date"
                    min={new Date()}
                    footer={errors.Sdate?.message}
                />

                <TextInput label="通信欄" name="message" footer={""} />
                <p className="text-xs px-10" style={{ color: "#347deb" }}>
                    ご購読・ご試読は、日本経済新聞　日経産業新聞　日経ＭＪ　東京新聞　東京中日スポーツ　デイリースポーツからお選び下さい。
                </p>
                <div className="py-3 lg:p-4">
                    <Button
                        type="button,submit"
                        title="送信"
                        disabled={promiseInProgress}
                        iconRight={<IconRight spin={promiseInProgress} />}
                        aria-label="送信"
                    />
                </div>
            </form>
        </FormProvider>
    )
}

export default Subscribe
