export const ContactGoogleForm = {
    action:
        "https://docs.google.com/forms/u/5/d/e/1FAIpQLScV37yqEr3W543yFBwqFSGEFEzcr-WxxVChrLb4H8U3CjsGtw/formResponse",

    name: "entry.1100603440",
    address: "entry.676130959",
    telephone: "entry.1215780313",
    email: "entry.1255474003",
    sub: "entry.920942191",
    plan: "entry.2094304352",
    Sdate: "entry.875628810",
    message: "entry.818439997",
}
