export const ContactGoogleForm = {
    action:
        "https://docs.google.com/forms/u/5/d/e/1FAIpQLSfH4tcqJTvjprK_ntU6j7PFNs1ceUuI3x-JAkwkFT0PcAGaMA/formResponse",

    name: "entry.2129419066",
    Eaddress: "entry.664688932",
    telephone: "entry.1382496432",
    email: "entry.510155169",
    Edate: "entry.515975327",
    move: "entry.2093576534",
    Saddress: "entry.1704654906",
    Sdate: "entry.1560918525",
    pay: "entry.980255279",
    other: "entry.637105894",
    message: "entry.1447802076",
}
