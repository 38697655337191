export const ContactGoogleForm = {
    action:
        "https://docs.google.com/forms/u/5/d/e/1FAIpQLSdnfJgGI9djRJO1goBpysVj53QBkrWYVxSALeKYGybwKazk2A/formResponse",

    name: "entry.1549921105",
    address: "entry.2116434571",
    telephone: "entry.1465699259",
    email: "entry.308461736",
    Sdate: "entry.871120575",
    start: "entry.1291187116",
    Edate: "entry.2042528811",
    end: "entry.1097489308",
    keep: "entry.1483747543",
    message: "entry.7963235",
}
